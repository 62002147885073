<template>
  <div class="footer">
    <div class="ui horizontal list">
      <div class="item ui left floated header">
        <img class="logo" src="@/assets/logo.svg" alt="Tayocash">
      </div>
      <div class="right">
        <div class="item">
          <a href="/termsandconditions">Terms of Use</a>
        </div>|
        <div class="item">
          <a href="/privacypolicy">Privacy Policy</a>
        </div>
        <div class="item">
          <a href="/amla">AMLA</a>
        </div>
        <div class="item">
          <a href="/erm">ERM</a>
        </div>
        <div class="item">
          <a href="/consumerprotection">Consumer Protection</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'footermenu'
}
</script>

<style scoped lang="scss"></style>
